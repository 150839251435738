import { useState, useContext } from "react";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { api_url, whitelist } from "../constants";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { APIContext } from "../connector";

import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is a required field"),
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format")
    .test("email_domain", "Must be a UM email", (value) => {
      return (
        value?.endsWith("@um.edu.my") || value?.endsWith("@siswa.um.edu.my") || whitelist.includes(value)
      );
    }),
  // password: Yup.string()
  //   .required("Password is a required field")
  //   .min(8, "Password must be at least 8 characters"),
  user_type: Yup.string().required("User type is a required field"),
  gender: Yup.string().required("Gender is required"),
  agree: Yup.boolean().oneOf([true], "You must accept the terms and conditions"),
});

const Register = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setUserData } = useContext(APIContext);
  const [phoneValue, setPhoneValue] = useState();

  console.log("State:", state);

  return (
    <>
      {/* Wrapping form inside formik tag and passing our schema to validationSchema prop */}
      <Formik
        validationSchema={schema}
        initialValues={{
          name: state?.name,
          email: state?.email,
          // password: "",
          phone_number: "",
          user_type: "",
          gender: "",
          agree: false,
        }}
        onSubmit={(values) => {
          console.log(values);

          // TODO: add phone number properly to the form values and enable validation errors
          values["phone_number"] = phoneValue;
          values["google_auth_token"] = state?.access_token;

          // send a POST request to the server
          fetch(`${api_url}/api/register`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...values, ...state }),
          })
            .then((response) => {
              if (!response.ok) {
                return response.text().then((text) => {
                  console.log(text);
                  throw new Error(`Error registering user: ${text}`);
                });
              }
              return response.json();
            })
            .then((data) => {
              localStorage.setItem("accessToken", data.token?.access);
              localStorage.setItem("refreshToken", data.token?.refresh);
              localStorage.setItem("google_auth_token", data?.google_auth_token);
              setUserData(data);
              if (data.user_type === "CLIENT") {
                navigate("/booking", { state: { ...data } });
              } else {
                navigate("/driverRegister", { state: { ...data } });
              }
            })
            .catch((e) => {
              toast.error(`${e.message} `, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <div className="register">
            <div className="form">
              {/* Passing handleSubmit parameter tohtml form onSubmit property */}
              <form noValidate onSubmit={handleSubmit}>
                <span className="page-title">Create New Account</span>
                <Link to="/login" className="footerLink">
                  Already Registered? Log in here
                </Link>
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                <p className="huh">Name</p>
                <input
                  // type="name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder="Name"
                  className="form-control inp_text"
                  id="name"
                />
                {/* If validation is not passed show errors */}
                <p className="error">{errors.name && touched.name && errors.name}</p>
                <p className="huh">Gender</p>
                <ul className="choose-user">
                  <li>
                    <Field type="radio" id="male" name="gender" value="M" />
                    <label for="male">Male</label>
                  </li>
                  <li>
                    <Field type="radio" id="female" name="gender" value="F" />
                    <label for="female">Female</label>
                  </li>
                </ul>
                <p className="error">{errors.gender && touched.gender && errors.gender}</p>
                {/* <p className="error"{errors.gender && touched.gender && errors.gender}></p> */}
                <p className="huh">Email</p>
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder="Email"
                  className="form-control inp_text"
                  id="email"
                  disabled
                />
                {/* If validation is not passed show errors */}
                <p className="error">{errors.email && touched.email && errors.email}</p>
                {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                {/* <p className="huh">Password</p> */}
                {/* <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="Password"
                  className="form-control"
                /> */}
                {/* If validation is not passed show errors */}
                {/* <p className="error">{errors.password && touched.password && errors.password}</p> */}
                <p className="huh">Phone Number</p>
                {/* <input
                  id="phone_number"
                  name="phone_number"
                  type="phone_number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone_number}
                  placeholder="Phone Number"
                  className="form-control inp_text"
                /> */}
                {/* <IntlTelInput
                    initOptions={{
                      initialCountry: "my",
                      countrySearch: true,
                      formatOnDisplay: true,
                      formatAsYouType: true,
                      showSelectedDialCode: true,
                      autoPlaceholder: "aggressive",
                    }}
                  /> */}
                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="MY"
                  value={phoneValue}
                  onChange={setPhoneValue}
                  onBlur={handleBlur}
                  id="phone_number"
                  name="phone_number"
                  error={
                    phoneValue
                      ? isValidPhoneNumber(phoneValue)
                        ? undefined
                        : "Invalid phone number"
                      : "Phone number required"
                  }
                />
                {/* <p className="error">
                  {errors.phone_number &&
                    touched.phone_number &&
                    errors.phone_number}
                </p> */}
                <ul className="choose-user">
                  <li>
                    <Field type="radio" id="user" name="user_type" value="CLIENT" />
                    <label for="user">User</label>
                  </li>
                  <li>
                    <Field type="radio" id="rider" name="user_type" value="DRIVER" />
                    <label for="rider">Driver</label>
                  </li>
                </ul>

                <div className="consent">
                  <input
                    type="checkbox"
                    name="agree"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.agree}
                    className="form-check-input"
                    id="agree"
                  />
                  <label htmlFor="agree" className="form-check-label">
                    I have read and agree to the{" "}
                    <a
                      href="https://www.dropbox.com/scl/fi/78limermhmozzey435dcd/Privacy-Policy.pdf?rlkey=3mq6xvc388ha17w3n2vneyrfu&st=aqjuph1i&dl=0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions
                    </a>
                  </label>
                </div>
                <p className="error">{errors.agree && errors.agree}</p>
                {/* Click on submit button to submit the form */}
                <button type="submit">Sign Up</button>
              </form>
            </div>
          </div>
        )}
      </Formik>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default Register;
