import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import maleIcon from "../static/images/male-icon.png";
import whatsapp from "../static/images/whatsapp.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIContext } from "../connector";

const DriverOTW = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const [simerSconds, setTimerSeconds] = useState(-1); // Initial time in seconds

  const trip_details = state?.trip_details || {};
  const driver_details = state?.driver_details || {};

  const wa_link = `https://wa.me/${driver_details?.phone_number}`;
  const { lastJsonMessage } = useContext(APIContext);

  let driverPfpUrl = maleIcon;

  if (lastJsonMessage?.data?.driver_details?.profile_picture) {
    driverPfpUrl =
      "https://sapu-um.com/media/" +
      lastJsonMessage?.data?.driver_details?.profile_picture;
  }
  useEffect(() => {
    console.log("Got a new message:", { lastJsonMessage });
    if (lastJsonMessage?.type === "trip_completed") {
      toast.success("Ride Complete! You will be returned to the homepage.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimerSeconds(5);
    }
    if (lastJsonMessage?.type === "trip_accepted") {
      let sharedValue =
        lastJsonMessage?.data?.trip_details?.price_offer -
        lastJsonMessage?.data?.trip_details?.final_price +
        1;

      toast.info("Driver is sharing RM " + sharedValue.toString() + " with you!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    let intervalId;
    if (simerSconds > 0) {
      intervalId = setInterval(() => {
        setTimerSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    if (simerSconds === 0) {
      clearInterval(intervalId);
      // Perform your function when timer hits 0
      navigate("/booking", { state: lastJsonMessage?.data });
    }
    return () => clearInterval(intervalId);
  }, [simerSconds, navigate, lastJsonMessage]);

  useEffect(() => {
    //// This will trigger a confirmation dialog when the user tries to leave the page
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support
      }
      return ""; // Legacy method for cross browser support
    };
  }, []);

  return (
    <>
      <div className="basic">
        <div className="basic-body form">
          <div className="dotw">Driver on the way..</div>
          <hr />
          <div className="found">
            <mark>Driver</mark>
            <div className="profile">
              <img
                src={driverPfpUrl}
                alt=""
                // className="centered-image"
                width="50"
                height="50"
              />
              <p>
                {driver_details?.name} ({driver_details?.gender})
                <br />
                {driver_details?.car_model} - {driver_details?.car_plate_number}
              </p>
            </div>
            <p className="huh">From</p>
            <input
              type="pickup"
              name="pickup"
              value={trip_details?.from_location}
              className="form-control inp_text"
              id="pickup"
              disabled
            />
            <p className="huh">To</p>
            <input
              type="destination"
              name="destination"
              value={trip_details.to_location}
              className="form-control inp_text"
              id="destination"
              disabled
            />
            <p className="price">RM {trip_details.final_price}</p>
            <div className="call-options">
              <a href={wa_link} target="_blank" rel="noreferrer">
                <img
                  src={whatsapp}
                  alt=""
                  // className="centered-image"
                  width="75"
                  height="75"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default DriverOTW;
