import { Outlet, useNavigate } from "react-router-dom";
import logo from "./static/images/Logo.png";
import { useEffect, useContext, useState } from "react";
import { APIContext } from "./connector";
import { api_url } from "./constants";

const isWebview = () => {
  const userAgent = navigator.userAgent || "";

  // Telegram WebView detection
  const isTelegramWebView = Boolean(typeof window?.TelegramWebview !== "undefined");

  // iOS WebView detection
  const isIOSWebView = Boolean(window.webkit?.messageHandlers);

  // Android WebView detection
  const isAndroidWebView = Boolean(
    userAgent.includes("wv") || (userAgent.includes("Android") && !userAgent.includes("Chrome"))
  );

  // Combine all heuristics
  return isTelegramWebView || isIOSWebView || isAndroidWebView;
};

const Layout = () => {
  const navigate = useNavigate();
  const { reconnectSocket, setUserData } = useContext(APIContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // TODO 1
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const googleAuthToken = localStorage.getItem("google_auth_token");

    const path = window.location.pathname;
    if (!(path.startsWith("/login") || path.startsWith("/register") || path === "/")) {
      if ((!accessToken && !refreshToken) || !googleAuthToken) {
        navigate("/login");
        setLoading(false);
      } else if (accessToken) {
        // console.log("Layour calls API");
        // TODO: Create a new endpoint that will authorize token, refresh if possible,
        // determine redirect path based on user status, and return user data
        fetch(`${api_url}/api/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          credentials: "same-origin",
        })
          .then((response) => {
            if (!response.ok) {
              navigate("/login");
            }
            return response.json();
          })
          .then((user_data) => {
            reconnectSocket();
            console.log("user_data", user_data);
            setUserData(user_data);
            if (user_data?.user_type === "CLIENT") {
              navigate("/booking", { state: { ...user_data } });
            } else if (user_data?.user_type === "DRIVER") {
              if (user_data?.driver_info?.is_approved_driver === true) {
                navigate("/driver", { state: { ...user_data } });
              } else if (user_data?.driver_info?.is_approved_driver === false) {
                navigate("/waiting", { state: { ...user_data } });
              } else {
                navigate("/driverRegister", { state: { ...user_data } });
              }
            } else if (user_data?.user_type === "ADMIN") {
              navigate("/driverList", { state: { ...user_data } });
            }
          })
          .then(() => setLoading(false));
      }
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div className="main-container">
      <img src={logo} alt="" className="header-logo" />
      {isWebview() ? (
        <div className="webview-warning">
          <h2>
            Warning: This app does not support usage inside a Telegram/Instagram webview. Please open this
            link in a browser.
          </h2>
        </div>
      ) : (
        <div>{loading ? <h1>Loading...</h1> : <Outlet />}</div>
      )}
    </div>
  );
};

export default Layout;
