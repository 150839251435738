import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import tick from "../static/images/tick.png";
import maleIcon from "../static/images/male-icon.png";
import "../CSS/DriverFound.scss";
import { APIContext } from "../connector";

const MAX_RESPONSE_TIME = 60;

const DriverFound = () => {
  const navigate = useNavigate();

  const { sendJsonMessage, lastJsonMessage } = useContext(APIContext);

  const { state } = useLocation();
  console.log("DriverFound state", state);
  const trip_details = state?.trip_details || {};
  const driver_details = state?.driver_details || {};

  const [price, setPrice] = useState(trip_details?.price_offer);
  const [finalPrice, setFinalPrice] = useState(null);
  const [nego, setNego] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(MAX_RESPONSE_TIME);
  const [autoCancelled, setAutoCancelled] = useState(false);

  let driverPfpUrl = maleIcon;

  if (lastJsonMessage?.data?.driver_details?.profile_picture) {
    driverPfpUrl =
      "https://sapu-um.com/media/" +
      lastJsonMessage?.data?.driver_details?.profile_picture;
  }

  // Run when a new WebSocket message is received (lastJsonMessage)
  useEffect(() => {
    console.log("Got a new message:", { lastJsonMessage });
    if (lastJsonMessage?.type === "final_offer") {
      setFinalPrice(lastJsonMessage?.data?.trip_details.final_price);
      setWaiting(false);
    } else if (lastJsonMessage?.type === "trip_cancelled" && !autoCancelled) {
      navigate("/booking");
    } else if (lastJsonMessage?.type === "trip_accepted") {
      navigate("/driverOTW", { state: lastJsonMessage?.data });
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    let intervalId;

    if (!waiting) {
      intervalId = setInterval(() => {
        setRemainingSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (waiting) {
      setRemainingSeconds(60);
      clearInterval(intervalId);
    }
    if (remainingSeconds === 0) {
      clearInterval(intervalId);
      setAutoCancelled(true);
      sendJsonMessage({
        event: "cancel_trip",
        trip_id: trip_details?.trip_id,
      });
      toast.error("You took too long. Trip cancelled", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => navigate("/booking"), 5000);
    }
    return () => clearInterval(intervalId);
  }, [remainingSeconds, navigate, waiting]);

  useEffect(() => {
    //// This will trigger a confirmation dialog when the user tries to leave the page
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      e.preventDefault();
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support
      }
      return ""; // Legacy method for cross browser support
    };
  }, []);

  const handleCancel = () => {
    sendJsonMessage({
      event: "cancel_trip",
      trip_id: trip_details?.trip_id,
    });
  };

  const handleAccept = () => {
    sendJsonMessage({
      event: "accept_offer",
      trip_id: trip_details?.trip_id,
    });
    setWaiting(true);
  };

  const handleNegoClick = () => {
    setNego(!nego);
  };

  const handlePriceClick = (val) => {
    setPrice(price - val);
    setWaiting(true);
    sendJsonMessage({
      event: "negotiation_offer",
      data: JSON.stringify({
        trip_id: trip_details?.trip_id,
        price_offer: price - val,
      }),
    });
  };

  return (
    <>
      <div className="basic">
        <div className="basic-body">
          <img
            src={tick}
            alt=""
            // className="centered-image"
            width="80"
            height="80"
          />
          <span className="about">Driver Found</span>
          <hr />
          <div className="found">
            <mark>Driver</mark>
            <div className="profile">
              <img
                src={driverPfpUrl}
                alt=""
                // className="centered-image"
                width="50"
                height="50"
              />
              <div>
                <div className="name">
                  {driver_details?.name} ({driver_details?.gender})
                </div>
                {driver_details?.car_model} - {driver_details?.car_plate_number}
              </div>
            </div>
            <p className="price">RM {finalPrice ?? price}</p>
            {finalPrice && (
              <div className="waiting">
                <span>Driver has set the final price to RM {finalPrice}</span>
              </div>
            )}
            {!waiting && !autoCancelled && (
              <>
                <div className="waiting">
                  <p>
                    You have{" "}
                    <span
                      style={{
                        color: remainingSeconds > 10 ? "#25591d" : "red",
                        fontWeight: 900,
                        fontSize: "1.2em",
                      }}
                    >
                      {remainingSeconds}
                    </span>{" "}
                    seconds to respond to the offer
                  </p>
                </div>
                <div className="button-array">
                  <button className="yes" onClick={handleAccept}>
                    Yes
                  </button>
                  <button className="no" onClick={handleCancel}>
                    No
                  </button>
                  {!finalPrice && (
                    <div className="nego">
                      {!nego && <button onClick={() => handleNegoClick()}>Nego</button>}
                      {nego && <button onClick={() => handlePriceClick(2)}>RM {price - 2}</button>}
                      {nego && <button onClick={() => handlePriceClick(1)}>RM {price - 1}</button>}
                    </div>
                  )}
                </div>
              </>
            )}
            {waiting && (
              <div className="waiting">
                <span>Waiting for Driver's response on offer of RM {price}...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};
export default DriverFound;
